import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { MadMarqueeProps } from "./typings";
import {
  CustomerNotification as InlineNotification,
  CustomerNotificationLocation as InlineNotificationLocation,
} from "@shared-ui/customer-notifications";
import { useLocation } from "react-router-dom";
import { buildReferrer } from "src/components/utility/UrlUtils";

export const MadMarqueeComponent = (props: MadMarqueeProps) => {
  const { page, context } = props;
  const urlLocation = useLocation();

  if (!page || !page.pageId) {
    return null;
  }

  const referer = buildReferrer(context, urlLocation, true);

  return (
    <InlineNotification
      inputs={{
        xPageId: page.pageId,
        notificationLocation: InlineNotificationLocation.FOOTER,
        optionalContext: {
          referer,
        },
      }}
      skipSsr
    />
  );
};

const MadMarquee = React.memo(withStores("flexModuleModelStore", "page", "context")(observer(MadMarqueeComponent)));

export default MadMarquee;
